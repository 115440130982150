<template>
    <div class="sld_prise_logins">
        <div class="sld_login_header_o">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img class='img' :src="platform==1?gongcaiLogo:jicaiLogo" :onerror='defaultImg' alt />
                </router-link>
                <div class="r_register_wrap">
                    {{L['已有账号？']}}
                    <a href="javascript:void(0)" class="go_register_btn" @click="goToPage('/login')">
                        {{L['去登录']}}
                    </a>
                </div>
            </div>
        </div>
        <div class="sld_login_content_o">
            <!-- <img class="bg" :src="configInfo.main_user_register_bg" :onerror='defaultBgImg' alt /> -->
            <div class="login">
              <!-- 入驻成功 start -->
              <div class="prise_regise" v-if="registerFlag">
                <img src="../../../assets/settledSucc.png" alt="" class="examine_img">
                <p class="examine_p">审核中</p>
                <!-- <div class="examine_div">
                  <p>供应商登录请访问下面地址,用会员账号密码</p>
                  <p class="examine_a">seller.duomengde.com</p>
                </div> -->
                <div class="examine_btn" @click="gologin">返回</div>
              </div>
              <!-- 入驻成功 end -->
              <!-- 审核失败 start-->
              <div class="prise_regise" v-if="!registerFlag">
                <img src="../../../assets/entryFailed.png" alt="" class="examine_img">
                <p class="examine_p">审核失败</p>
                <div class="examine_div examine_div_one">
                  <p>失败原因: </p>
                  <p class="examine_div_one_p">{{reason}}</p>
                </div>
                <div class="examine_btn" @click="goRegister">重新申请</div>
              </div>
              <!-- 审核失败 end-->
            </div>
        </div>
    </div>
</template>

<script>
    import { useRouter,useRoute } from 'vue-router';
    import { ref, getCurrentInstance, onMounted, watch,reactive } from 'vue';
    import { useStore } from 'vuex';
    import { ElMessage} from "element-plus";
    import { platform } from '@/utils/config';
    export default {
        name: "Register",
        setup() {
            const store = useStore();
            const errorMsg = ref();//错误提示
            const router = useRouter();
            const route = useRoute()
            const { proxy } = getCurrentInstance();
            const L = proxy.$getCurLanguage();
            const configInfo = ref(store.state.configInfo)
            const registerFlag = ref(true);
            const typeFlag = ref(false);
            const reason = ref('');//拒绝原因
            const gongcaiLogo = ref(require('@/assets/gongcaiLogo.jpg'));
            const jicaiLogo = ref(require('@/assets/jicaiLogo.jpg'));
            const defaultImg = ref('this.src="' + require('@/assets/common_top_logo.png') + '"');
            //通过replace方式跳转页面
            const goToPage = (type) => {
                router.replace({
                    path: type,
                });
            }

            //重新申请
            const goRegister = () =>{
                router.replace({
                    path: '/priseRegister',
                    query:{
                        memberId:route.query.memberId
                    }
                });
            }
            //重新申请
            const gologin = () =>{
                if(typeFlag.value){
                    router.replace({
                        path: '/member/index'
                    });
                return
                }
                router.replace({
                    path: '/login',
                    query:{
                        
                    }
                });
            }
            const getRegistry = ()=>{
                proxy
                .$get("v3/member/front/memberPassword/getAuditState",{memberId:route.query.memberId})
                .then(res => {
                if(res.state==200){
                    if(res.data.enterpriseState==2){
                        registerFlag.value = true
                    }
                    if(res.data.enterpriseState==4){
                        registerFlag.value = false
                        reason.value = res.data.auditReason
                    }
                }
                })
                .catch(() => {
                    //异常处理
                });
            }

            onMounted(() => {
                document.body.classList.remove('el-popup-parent--hidden');
                // if(route.query.reason){
                //     reason.value = route.query.reason
                //     registerFlag.value = false
                // }
                if(route.query.type==1){
                    typeFlag.value = true
                }
                //getRegistry()
            })
            return {
                L,
                errorMsg,
                goToPage,
                configInfo,
                registerFlag,
                reason,
                goRegister,
                typeFlag,
                gologin,
                getRegistry,
                gongcaiLogo,
                jicaiLogo,
                platform,
                defaultImg
            };
        },
    };
</script>
<style lang="scss" scoped>
    @import '../../../style/registrationReview.scss';

    .el-popup-parent--hidden {
        overflow: visible;
    }
</style>