<template>
    <div class="sld_collect_index wd">
        <!-- 会员基本信息 -->
        <div class="user_info_top">
            <div class="user_info_l fl">
                <!-- <div class="user_avatar sld_img_center" :class="{ user_avatar_o: memberList.info.memberUserType != 2 }">
                    <img :src="memberInfo.info.memberAvatar" class="head_img" />
                </div> -->
                <div class="user_avatar_r">
                    <b>{{ memberList.info.memberTrueName }} ({{ memberList.info.memberMobile }})</b>
                </div>
                <div class="memeber_hui">
                    <p v-if="memberList.info.memberUserType == 2">
                        {{ memberInfo.info.parentEnterpriseName ? memberInfo.info.parentEnterpriseName : "--" }}
                    </p>
                    <!-- <b>{{memberList.info.userType==0?'普通会员':memberList.info.userType==1?'企业会员':memberInfo.info.enterpriseName}}</b> -->
                    <b>{{
                        memberList.info.memberUserType == 0
                            ? "个人会员"
                            : memberList.info.memberUserType == 1
                            ? "企业会员"
                            : "企业子会员"
                    }}</b>
                </div>
                <router-link
                    :to="'/member/address/list'"
                    class="user_addr"
                    target="_blank"
                    :class="{ user_addr_o: memberList.info.memberUserType != 2 }"
                    ><span class="fl"><i class="iconfont">&#xe70f;</i>{{ L["会员收货地址"] }}</span>
                    <i class="fr iconfont">&#xe616;</i>
                </router-link>
            </div>
            <div class="infoCon clearfix">
                <div class="info_rcol">
                    <h4 class="user_title"><i class="iconfont">&#xe608;</i>{{ L["我的财产"] }}</h4>
                    <ul class="acco_info fl flex_row_center_center">
                        <li class="acco_item" v-if="memberList.info.isLookWallet">
                            <div>
                                <em ref="balance" id="balance"
                                    >￥ <span id="balanceTitle">{{ memberList.info.walletBalance }}</span></em
                                >
                                <p>{{ L["余额"] }}</p>
                                <div class="sld_link" @click="toRecharge">{{ L["充值"] }}</div>
                            </div>
                        </li>
                        <!-- <li class="acco_item">
              <div>
                <a class="sld_red_num">{{memberInfo.info.couponNum}}</a>
                <p>{{L['优惠券']}}</p>
                <router-link class="sld_link" :to="'/coupon'" target="_blank">{{L['领券']}}</router-link>
              </div>
            </li> -->
                        <!-- 2024/10/14注释 -->
                        <!-- <li class="acco_item">
              <div>
                <em>{{memberInfo.info.memberIntegral?memberInfo.info.memberIntegral:0}}</em>
                <p>{{L['积分']}}</p>
                <router-link class="sld_link" :to="'/member/myPoint'" target="_blank">{{L['查看']}}</router-link>
              </div>
            </li> -->
                        <!-- <li class="acco_item">
              <div>
                <em>{{memberInfo.info.followStoreNum}}</em>
                <p>{{L['店铺关注']}}</p>
                <router-link class="sld_link" :to="'/member/collect?type=store'" target="_blank">{{L['查看']}}
                </router-link>
              </div>
            </li> -->
                    </ul>
                </div>
            </div>
        </div>
        <!-- 会员订单信息  -->
        <div class="user_order clearfix">
            <div class="my_order fl">
                <h4 class="user_title"><i class="iconfont">&#xe619;</i>{{ L["我的订单"] }}</h4>
                <ul class="clearfix order_state_nav flex_row_center_center">
                    <li v-if="memberList.info.isLookOrder">
                        <router-link :to="'/member/order/list?orderState=10'" target="_blank">
                            <i class="iconfont">&#xe677;</i>
                            <p>{{ L["待支付"] }}</p>
                            <div class="tag" v-if="memberInfo.data.some((item) => item.state == 10 && item.total > 0)">
                                {{ memberInfo.data.find((item) => item.state == 10)?.total }}
                            </div>
                        </router-link>
                    </li>
                    <li v-else>
                        <div @click="nav('/member/order/list?orderState=10')">
                            <i class="iconfont">&#xe677;</i>
                            <p>{{ L["待支付"] }}</p>
                            <div class="tag" v-if="memberInfo.data.some((item) => item.state == 10 && item.total > 0)">
                                {{ memberInfo.data.find((item) => item.state == 10)?.total }}
                            </div>
                        </div>
                    </li>
                    <li v-if="memberList.info.isLookOrder">
                        <router-link :to="'/member/order/list?orderState=30'" target="_blank">
                            <i class="iconfont">&#xe676;</i>
                            <p>{{ L["待收货"] }}</p>
                            <div class="tag" v-if="memberInfo.data.some((item) => item.state == 30 && item.total > 0)">
                                {{ memberInfo.data.find((item) => item.state == 30)?.total }}
                            </div>
                        </router-link>
                    </li>
                    <li v-else>
                        <div @click="nav('/member/order/list?orderState=30')">
                            <i class="iconfont">&#xe676;</i>
                            <p>{{ L["待收货"] }}</p>
                            <div class="tag" v-if="memberInfo.data.some((item) => item.state == 30 && item.total > 0)">
                                {{ memberInfo.data.find((item) => item.state == 30)?.total }}
                            </div>
                        </div>
                    </li>
                    <!-- <li v-if="memberInfo.info.isLookOrder!=0">
            <router-link :to="'/member/order/list?orderState=40&evaluateState=1'" target="_blank">
              <i class="iconfont">&#xe678;</i>
              <p>{{L['待评价']}}</p>
              <div class="tag" v-if="memberInfo.info.toEvaluateOrder>0">{{memberInfo.info.toEvaluateOrder}}</div>
            </router-link>
          </li>
          <li v-else>
            <div @click="nav('/member/order/list?orderState=40&evaluateState=1')" >
              <i class="iconfont">&#xe678;</i>
              <p>{{L['待评价']}}</p>
              <div class="tag" v-if="memberInfo.info.toEvaluateOrder>0">{{memberInfo.info.toEvaluateOrder}}</div>
            </div>
          </li> -->
                    <li v-if="memberList.info.isLookOrder">
                        <router-link :to="'/member/order/aftersales'" target="_blank">
                            <i class="iconfont">&#xe67c;</i>
                            <p>{{ L["售后/退货"] }}</p>
                            <div class="tag" v-if="memberInfo.info.afterSaleNum > 0">{{ afterTotal }}</div>
                        </router-link>
                    </li>
                    <li v-else>
                        <div @click="nav('/member/order/aftersales')">
                            <i class="iconfont">&#xe67c;</i>
                            <p>{{ L["售后/退货"] }}</p>
                            <div class="tag" v-if="memberInfo.info.afterSaleNum > 0">
                                {{ memberInfo.info.afterSaleNum }}
                            </div>
                        </div>
                    </li>
                    <li v-if="memberList.info.isLookOrder">
                        <router-link :to="'/member/order/list'" target="_blank">
                            <span>
                                <i class="iconfont">&#xe634;</i>
                            </span>
                            <p>{{ L["全部订单"] }}</p>
                        </router-link>
                    </li>

                    <li v-else>
                        <div @click="nav('/member/order/list')">
                            <span>
                                <i class="iconfont">&#xe634;</i>
                            </span>
                            <p>{{ L["全部订单"] }}</p>
                        </div>
                    </li>
                </ul>
                <div v-if="order.list.length > 0">
                    <div
                        class="user-order-item clearfix"
                        v-for="(
                            { placeOrderTime, orderSumAmount, goodsDetails, memberOrderCode, orderState }, index
                        ) in order.list"
                        :key="index"
                    >
                        <div class="fl">
                            <router-link
                                class="fl img"
                                :to="`/goods/detail?productId=${goodsDetails[0]?.skuCode}`"
                                target="_blank"
                            >
                                <!-- <img :src="goodsDetails[0].goodsImage" alt=""> -->
                                <coverImage :src="goodsDetails[0]?.goodsImage" class="img"></coverImage>
                            </router-link>
                            <a class="fl">
                                <p>{{ goodsDetails[0]?.goodsNameJoin }}</p>
                                <p class="price">￥{{ orderSumAmount }}</p>
                                <p class="time">下单时间:{{ placeOrderTime }}</p>
                            </a>
                        </div>
                        <div class="fr">
                            <p class="orderSn">订单号:{{ memberOrderCode }}</p>
                            <p class="orderState">{{ formatOrderState(orderState) }}</p>
                            <a @click="seeOrderDetail(memberOrderCode)" style="cursor: pointer">{{ L["查看详情"] }}</a>
                        </div>
                    </div>
                </div>
                <div class="user_order_empty flex_row_center_center" v-else>
                    <img src="@/assets/member/member_empty.png" alt="" />
                    <p>{{ L["这里空空如也，快去挑选合适的商品吧~"] }}</p>
                </div>
            </div>
            <div class="my_follow fr" style="display: none">
                <!-- 购物车item -->
                <div class="my_follow_item cartItem">
                    <h4 class="user_title">
                        <i class="iconfont icon-gouwuche"></i>{{ L["购物车"] }} ({{ cartLen }})<router-link
                            :to="'/cart/index'"
                            target="_blank"
                            >{{ L["查看更多"] }}></router-link
                        >
                    </h4>
                    <ul class="clearfix">
                        <template v-for="(storeItem, storeIndex) in cartData.data.storeCartGroupList" :key="storeIndex">
                            <template
                                v-for="(promotItem, promotIndex) in storeItem.promotionCartGroupList"
                                :key="promotIndex"
                            >
                                <li
                                    class="list_item"
                                    v-for="(cartItem, cartIndex) in promotItem.cartList"
                                    :key="cartIndex"
                                >
                                    <a @click="toProductDetail(cartItem.productId)">
                                        <img :src="cartItem.productImage" alt="" />
                                    </a>
                                </li>
                            </template>
                        </template>
                        <div class="no_footprint" v-show="!cartLen">暂无商品~</div>
                    </ul>
                </div>

                <!-- 关注店铺item -->
                <div class="my_follow_item store">
                    <h4 class="user_title">
                        <i class="iconfont">&#xe679;</i>{{ L["店铺关注"] }} ({{ memberInfo.info.followStoreNum }})
                        <router-link :to="'/member/collect?type=store'" target="_blank"
                            >{{ L["查看更多"] }}></router-link
                        >
                    </h4>
                    <ul class="clearfix">
                        <li
                            class="list_item"
                            v-for="({ storeLogo, storeId }, index) in collectStore.store"
                            :key="index"
                        >
                            <router-link :to="`/store/index?vid=${storeId}`" target="_blank">
                                <img :src="storeLogo" alt="" />
                            </router-link>
                        </li>
                        <div class="no_footprint" v-show="!collectStore.store.length">暂无关注店铺~</div>
                    </ul>
                </div>

                <!-- 收藏商品item -->
                <div class="my_follow_item goods">
                    <h4 class="user_title">
                        <i class="iconfont">&#xe67d;</i>{{ L["我的收藏"] }} ({{ memberInfo.info.followProductNum }})
                        <router-link :to="'/member/collect'" target="_blank">{{ L["查看更多"] }}></router-link>
                    </h4>
                    <ul class="clearfix">
                        <li
                            class="list_item"
                            v-for="({ productImage, productId }, index) in collectGoods.goods"
                            :key="index"
                        >
                            <a @click="toProductDetail(productId)">
                                <img :src="productImage" alt="" />
                            </a>
                        </li>
                        <div class="no_footprint" v-show="!collectGoods.goods.length">暂无收藏商品~</div>
                    </ul>
                </div>

                <!-- 足迹item -->
                <div class="my_follow_item footprint">
                    <h4 class="user_title">
                        <i class="iconfont">&#xe67f;</i>{{ L["我的足迹"] }} ({{ memberInfo.info.lookLogNum }})
                        <router-link :to="'/member/footprint'" target="_blank">{{ L["查看更多"] }}></router-link>
                    </h4>
                    <ul class="clearfix">
                        <li class="list_item" v-for="({ goodsImage, productId }, index) in looklog.log" :key="index">
                            <a @click="toProductDetail(productId)">
                                <img :src="goodsImage" alt="" />
                            </a>
                        </li>
                        <div class="no_footprint" v-show="!looklog.log.length">暂无足迹~</div>
                    </ul>
                </div>
            </div>
        </div>
        <!-- 热销推荐 -->
        <div class="user_hot_goods clearfix">
            <h3>{{ L["热销推荐"] }}</h3>
            <ul class="clearfix">
                <li
                    v-for="({ goodsMainImage, marqueImage, skuName, goodsPrice, skuCode }, index) in hotRecom.goods"
                    :key="index"
                >
                    <a @click="toProductDetail(skuCode)">
                        <div class="img">
                            <img class="lazy" :src="marqueImage ? marqueImage : goodsMainImage" alt="" />
                        </div>
                        <p class="hot_goods_name">{{ skuName }}</p>
                        <p class="hot_goods_price">
                            ￥<em>{{ goodsPrice }}</em>
                        </p>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import { reactive, onMounted, getCurrentInstance, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import axios from "axios";
import { apiUrlSub } from "../../utils/config";
import { platform } from "@/utils/config";
export default {
    name: "index",
    setup() {
        const router = useRouter();
        const store = useStore();
        const memberInfo = reactive({ info: {}, data: [] });
        const memberList = reactive({ info: {} }); //会员信息
        const looklog = reactive({ log: [] });
        const looklogLen = ref(0);
        const cartData = reactive({ data: [] });
        const cartLen = ref(0);
        const afterTotal = ref(0); //售后/退货数量
        const collectStore = reactive({ store: [] });
        const collectGoods = reactive({ goods: [] });
        const hotRecom = reactive({ goods: [] });
        const order = reactive({ list: [] });
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();

        // 使用 computed 创建计算属性
        const formatOrderState = computed(() => {
            return (value) => {
                switch (value) {
                    case 0:
                        return "已取消";
                    case 1:
                        return "待审批";
                    case 10:
                        return "待支付";
                    case 20:
                        return "待发货";
                    case 30:
                        return "待收货";
                    case 40:
                        return "已完成";
                    case 50:
                        return "已关闭";
                    default:
                        return "未知";
                }
            };
        });

        const getInitInfo = () => {
            //获取会员信息数据
            let params = { memberId: store.state.memberInfo.memberId };
            proxy.$get("api/shopp/member/order/state/total").then((res) => {
                if (res.code == 200) {
                    memberInfo.data = res.data;
                }
            });
            proxy.$get("api/shopp/order/after/total", params).then((res) => {
                if (res.code == 200) {
                    afterTotal.value = res.data;
                }
                getOrder();
            });
            // proxy.$get('/v3/member/front/member/getInfo').then(res => {
            //   if (res.state == 200) {
            //     memberInfo.info = res.data
            //     store.commit("updateMemberInfo", memberInfo.info); //将购物车数据存储到vuex的store中
            //     getOrder()
            //   }
            // })
        };
        const nav = () => {
            ElMessage.warning("暂无权限访问");
        };
        const getMemberInfo = () => {
            //获取会员信息数据
            memberList.info = store.state.memberInfo;
            // proxy.$get('/v3/member/front/member/memberInfo').then(res => {
            //   if (res.state == 200) {
            //     memberList.info = res.data
            //     // store.commit("updateMemberInfo", memberInfo.info); //将购物车数据存储到vuex的store中
            //   }
            // })
        };
        //去充值
        const toRecharge = () => {
            proxy.$get("api/shopp/member/bank/card/list").then((res) => {
                if (res.code == 200) {
                    if (memberList.info.memberUserType != 0 && res.data.length == 0) {
                        ElMessage.warning("请先去绑定银行卡");
                        return;
                    }
                    // 判断是否可以充值
                    //   if(!opening.value){
                    //     ElMessageBox.confirm(
                    //     '尚未开通钱包，请开通钱包后进行充值提现',
                    //     '提示',
                    //     {
                    //       confirmButtonText: '立即开通钱包 >',
                    //       confirmButtonClass: 'dilog_button',
                    //       showCancelButton: false,
                    //       center: true,
                    //     }
                    //   )
                    //     .then(() => {
                    //       router.push('/member/AccountNow')
                    //       return
                    //     })
                    //     .catch(() => {

                    //     })
                    //   }else{
                    router.push({
                        path: "/member/recharge"
                    });
                    //   }
                }
            });
        };

        const getLookLog = () => {
            //获取我的足迹数据
            proxy.$get("/v3/member/front/productLookLog/list").then((res) => {
                if (res.state == 200) {
                    looklog.log = res.data.list[0] ? res.data.list[0].productLookLogInfoList.slice(0, 4) : [];
                    looklogLen.value = res.data.list[0] ? res.data.list[0].productLookLogInfoList.length : 0;
                }
            });
        };

        const getCartItem = () => {
            let params = { userId: store.state.memberInfo.memberId };
            proxy.$get("api/shopp/cart/queryCart", params).then((res) => {
                //原接口：v3/business/front/cart/cartList
                if (res.code == 200) {
                    cartData.data = res.data ? res.data : [];
                    cartLen.value = res.data?.count ? res.data.count : 0;
                }
            });
        };

        const getCollectStore = () => {
            proxy.$get("/v3/member/front/followStore/list").then((res) => {
                if (res.state == 200) {
                    collectStore.store = res.data.storeList;
                }
            });
        };

        //const getCollectGoods = () => {
        //  proxy.$get('/v3/member/front/followProduct/list').then(res => {
        //    if (res.state == 200) {
        //      collectGoods.goods = res.data.list
        //    }
        //  })
        //}

        const getHotReco = () => {
            //获取热门推荐数据
            let params = {
                queryType: "cart"
            };
            proxy.$get("api/shopp/goods/actualSales").then((res) => {
                //原接口：v3/goods/front/goods/recommendList
                if (res.code == 200) {
                    hotRecom.goods = res.data;
                    hotRecom.goods.map((item) => (item.goodsPrice = new Number(item.goodsPrice).toFixed(2)));
                }
            });
        };

        const getOrder = () => {
            //获取订单
            let params = {
                current: 1,
                orderSource:platform
            };
            if (memberList.info.isLookOrder) {
                proxy.$get("api/shopp/member/order/page", params).then((res) => {
                    //原接口：/v3/business/front/orderInfo/list
                    if (res.code == 200) {
                        order.list = res.data.records;
                        order.list.map((item) => (item.totalMoney = new Number(item.totalMoney).toFixed(2)));
                    }
                });
            }
        };

        const seeOrderDetail = (orderSn) => {
            //去往订单详情
            if (memberList.info.isLookOrder) {
                let newWin = router.resolve({
                    path: "order/detail",
                    query: {
                        orderSn: orderSn
                    }
                });
                window.open(newWin.href, "_blank");
            } else {
                ElMessage.warning("暂无权限访问");
            }
        };

        const toProductDetail = (productId) => {
            let newWin = router.resolve({
                path: "/goods/detail",
                query: {
                    productId: productId
                }
            });
            window.open(newWin.href, "_blank");
        };

        onMounted(() => {
            getInitInfo();
            getMemberInfo();
            // getLookLog()
            getHotReco();
            getCartItem();
            // getCollectStore()
            //getCollectGoods()
        });

        return {
            memberInfo,
            L,
            looklog,
            hotRecom,
            formatOrderState,
            getOrder,
            order,
            seeOrderDetail,
            toProductDetail,
            looklogLen,
            cartData,
            cartLen,
            afterTotal,
            collectStore,
            collectGoods,
            memberList,
            toRecharge,
            nav,
            platform
        };
    }
};
</script>
<style lang="scss" scoped>
@import "@/style/member/index.scss";
@import "@/style/base.scss";

.user_order_empty {
    padding: 90px;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;

    p {
        margin-left: 12px;
        color: #666666;
    }
}

.no_footprint {
    height: 73px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #666666;
}
</style>
