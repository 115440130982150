<!--个人中心左侧公共导航 @zjf-2020-12-26-->
<template>
    <div class="sld_member_left_nav">
        <div v-for="(item, index) in leftNavMenu" :key="index">
            <!-- v-if="!item.limit" -->
            <div>
                <router-link :to="{ path: item.path }" class="menu">
                    <i :class="{ iconfont: true, [item.icon]: true }"></i>
                    {{ item.title }}
                </router-link>
                <template v-if="item.children && item.children.length > 0">
                    <template v-for="(item_child, index_child) in item.children" :key="index_child">
                        <router-link
                            v-if="testIfShow(item_child)"
                            :to="{
                                path: item_child.path,
                                query:
                                    item_child.element == 'AfterSalesListReturn'
                                        ? { type: 'return' }
                                        : item_child.element == 'AfterSalesListReturns'
                                        ? { type: 'returns' }
                                        : null
                            }"
                            :class="{ submenu: true, cur_code_active: curCode == item_child.element }"
                            >{{ item_child.title }}</router-link
                        >
                    </template>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import { getCurrentInstance, watchEffect, ref, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { platform } from "@/utils/config";
export default {
    name: "MemberLeftNav",
    components: {},
    setup() {
        const store = useStore();
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const curCode = ref("");
        const memberInfo = reactive({ data: store.state.memberInfo });
        // console.log(memberInfo.data.roleIds)
        watchEffect(() => {
            let tmpPath = router.currentRoute.value.fullPath;
            if (router.currentRoute.value.name != undefined && router.currentRoute.value.name) {
                curCode.value = router.currentRoute.value.name;
                if (tmpPath == "/member/collect?type=store") {
                    curCode.value = "memberCollectStore"; //关注店铺
                } else if (
                    tmpPath == "/member/order/aftersales?type=return" ||
                    tmpPath.indexOf("/member/order/refund/detail?curTitleId=3") > -1
                ) {
                    curCode.value = "AfterSalesListReturn"; //我的退货退款
                } else if (
                    tmpPath == "/member/order/aftersales" ||
                    tmpPath.indexOf("/member/order/refund/detail?curTitleId=1") > -1
                ) {
                    curCode.value = "AfterSalesList"; //我的退款
                } else if (
                    tmpPath == "/member/order/aftersales?type=returns" ||
                    tmpPath.indexOf("/member/order/refund/detail?curTitleId=4") > -1
                ) {
                    curCode.value = "AfterSalesListReturns"; //换货
                }
            }
        });
        const leftNavMenu = ref([
            {
                code: "tarde",
                icon: "icon-gerenzhongxinjiaoyizhongxin",
                name: L["交易中心"],
                path: "/member/index",
                child: [
                    {
                        code: "memberOrderList",
                        name: L["我的订单"],
                        path: "/member/order/list"
                    },
                    {
                        code: "memberApprovalList",
                        name: "我的审批",
                        path: "/member/order/approvalList"
                    },
                    {
                        code: "memberSupplierOrders",
                        name: "我的供应商订单",
                        path: "/member/order/supplierOrders"
                    }
                    // {
                    //   code: "MemberOrderEvaluation",
                    //   name: L["交易评价/晒单"],
                    //   path: "/member/order/evaluation"
                    // },
                    // {
                    //   code: "pointOrderList",
                    //   name: "积分兑换订单",
                    //   path: "/member/pointOrder/list"
                    // }
                ]
            },
            {
                code: "procurement",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["采购计划"],
                path: "/member/index",
                child: [
                    {
                        code: "procurementPlanList",
                        name: L["单次计划"],
                        path: "/member/procurement/procurementPlanList"
                    },
                    {
                        code: "monthlylist",
                        name: "月度计划",
                        path: "/member/procurement/monthlylist"
                    },
                    {
                        code: "procurementPlanApprovalList",
                        name: "采购计划审批",
                        path: "/member/procurement/procurementPlanApprovalList"
                    }
                ]
            },

            {
                code: "service",
                icon: "icon-gerenzhongxinkehufuwu",
                name: L["客户服务"],
                path: "/member/index",
                child: [
                    {
                        code: "AfterSalesList",
                        name: L["我的退款"],
                        path: "/member/order/aftersales"
                    },
                    {
                        code: "AfterSalesListReturn",
                        query: { type: "return" },
                        name: L["我的退货"],
                        path: "/member/order/aftersales"
                    },
                    {
                        code: "AfterSalesListReturns",
                        query: { type: "returns" },
                        name: L["我的换货"],
                        path: "/member/order/aftersales"
                    }
                ]
            },
            {
                code: "member",
                icon: "icon-gerenzhongxinhuiyuanzhongxin",
                name: L["会员中心"],
                path: "/member/index",
                child: [
                    {
                        code: "MemberInfo",
                        name: L["会员信息"],
                        path: "/member/info"
                    },
                    {
                        code: "MemberAccount",
                        name: L["账号安全"],
                        path: "/member/account"
                    },
                    {
                        code: "MemberPhoneMange",
                        name: L["手机号管理"],
                        path: "/member/phone"
                    },
                    // {
                    //   code: "memberEmailMange",
                    //   name: L["邮箱管理"],
                    //   path: "/member/email"
                    // },
                    // {
                    //   code: "MemberLoginPassword",
                    //   name: L["登录密码"],
                    //   path: "/member/pwd/login"
                    // },
                    {
                        code: "MemberPayPassword",
                        name: L["支付密码"],
                        path: "/member/pwd/pay"
                    },
                    {
                        code: "MemberResetPassword",
                        name: L["重置密码"],
                        path: "/member/pwd/reset"
                    },
                    {
                        code: "memberAddressList",
                        name: L["收货地址"],
                        path: "/member/address/list"
                    }
                ]
            },
            {
                code: "money",
                icon: "icon-gerenzhongxincaichanzhongxin",
                name: L["我的钱包"],
                path: "/member/index",
                child: [
                    {
                        code: "MemberBalance",
                        name: L["我的余额"],
                        path: "/member/balance"
                    },
                    {
                        code: "MemberRecharge",
                        name: L["账户充值"],
                        path: "/member/recharge"
                    },
                    {
                        code: "MemberbankCard",
                        name: "我的银行卡",
                        path: "/member/bankCard"
                    }
                    // {
                    //   code: "MemberCoupon",
                    //   name: "我的优惠券",
                    //   path: "/member/coupon"
                    // },
                    // {
                    //   code: "MyPoint",
                    //   name: "我的积分",
                    //   path: "/member/myPoint"
                    // }
                ]
            },
            {
                code: "account",
                icon: "icon-zhanghao",
                limit: store.state.memberInfo.memberUserType != 2 ? false : true,
                name: "子账号",
                path: "/member/index",
                child: [
                    {
                        code: "memberAccountManage",
                        name: "权限组",
                        path: "/member/account/manage"
                    },
                    {
                        code: "MemberCollectList",
                        name: "子账号管理",
                        path: "/member/account/list"
                    },
                    {
                        code: "MemberSubSecter",
                        name: "部门管理",
                        path: "/member/account/Sub-sector"
                    }
                ]
            },
            {
                code: "mySupplier",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["我的供应商"],
                path: "/member/index",
                //limit:store.state.memberInfo.isLookWallet==1?false:true,
                child: [
                    {
                        code: "mySupplierList",
                        name: L["我的供应商"],
                        path: "/member/mySupplier/mySupplierList"
                    }
                    //{
                    //  code: "SupplierBlacklist",
                    //  name: L["供应商黑名单"],
                    //  path: "/member/mySupplier/SupplierBlacklist"
                    //},
                ]
            },
            {
                code: "payment",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["账期管理"],
                path: "/member/index",
                //limit:store.state.memberInfo.isLookWallet==1?false:true,
                child: [
                    {
                        code: "MonthlyStatement",
                        name: L["当月账单"],
                        path: "/member/payment/MonthlyStatement"
                    },
                    {
                        code: "Settlement",
                        name: L["账期结算"],
                        path: "/member/payment/Settlement"
                    },
                    {
                        code: "HistoricalStatement",
                        name: L["历史账单"],
                        path: "/member/payment/HistoricalStatement"
                    }
                    //{
                    //  code: "SupplierBlacklist",
                    //  name: L["供应商黑名单"],
                    //  path: "/member/mySupplier/SupplierBlacklist"
                    //},
                ]
            }
        ]);
        // 子账号登录左侧菜单
        const leftNavMenu2 = [
            {
                code: "tarde",
                icon: "icon-gerenzhongxinjiaoyizhongxin",
                name: L["交易中心"],
                path: "/member/index",
                child: [
                    {
                        code: "memberOrderList",
                        name: L["我的订单"],
                        path: "/member/order/list"
                    },
                    {
                        code: "memberApprovalList",
                        name: "我的审批",
                        path: "/member/order/approvalList"
                    },
                    {
                        code: "memberSupplierOrders",
                        name: "我的供应商订单",
                        path: "/member/order/supplierOrders"
                    }

                    // {
                    //   code: "MemberOrderEvaluation",
                    //   name: L["交易评价/晒单"],
                    //   path: "/member/order/evaluation"
                    // },
                    // {
                    //   code: "pointOrderList",
                    //   name: "积分兑换订单",
                    //   path: "/member/pointOrder/list",
                    // }
                ]
            },
            // {
            //   code: "collect",
            //   icon: "icon-gerenzhongxinguanzhu",
            //   name: L["关注中心"],
            //   path: "/member/index",
            //   child: [
            //     {
            //       code: "MemberCollect",
            //       name: L["我的收藏"],
            //       path: "/member/collect"
            //     },
            //     {
            //       code: "memberCollectStore",
            //       query: { type: "store" },
            //       name: L["关注店铺"],
            //       path: "/member/collect"
            //     },
            //     {
            //       code: "MemberFootprint",
            //       name: L["我的足迹"],
            //       path: "/member/footprint"
            //     }
            //   ]
            // },
            {
                code: "procurement",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["采购计划"],
                path: "/member/index",
                limit: store.state.memberInfo.memberUserType == 2 ? false : true,
                child: [
                    {
                        code: "procurementPlanList",
                        name: L["单次计划"],
                        path: "/member/procurement/procurementPlanList"
                    },

                    {
                        code: "monthlylist",
                        name: "月度计划",
                        path: "/member/procurement/monthlylist"
                    },
                    {
                        code: "procurementPlanApprovalList",
                        name: "采购计划审批",
                        path: "/member/procurement/procurementPlanApprovalList"
                    }
                ]
            },
            {
                code: "service",
                icon: "icon-gerenzhongxinkehufuwu",
                name: L["客户服务"],
                path: "/member/index",
                child: [
                    {
                        code: "AfterSalesList",
                        name: L["我的退款"],
                        path: "/member/order/aftersales"
                    },
                    {
                        code: "AfterSalesListReturn",
                        query: { type: "return" },
                        name: L["我的退货"],
                        path: "/member/order/aftersales"
                    },
                    {
                        code: "AfterSalesListReturns",
                        query: { type: "returns" },
                        name: L["我的换货"],
                        path: "/member/order/aftersales"
                    }
                ]
            },
            {
                code: "member",
                icon: "icon-gerenzhongxinhuiyuanzhongxin",
                name: L["会员中心"],
                path: "/member/index",
                child: [
                    {
                        code: "MemberInfo",
                        name: L["会员信息"],
                        path: "/member/info"
                    },
                    {
                        code: "MemberAccount",
                        name: L["账号安全"],
                        path: "/member/account"
                    },
                    {
                        code: "MemberPhoneMange",
                        name: L["手机号管理"],
                        path: "/member/phone"
                    },
                    // {
                    //   code: "memberEmailMange",
                    //   name: L["邮箱管理"],
                    //   path: "/member/email"
                    // },
                    // {
                    //   code: "MemberLoginPassword",
                    //   name: L["登录密码"],
                    //   path: "/member/pwd/login"
                    // },
                    {
                        code: "MemberPayPassword",
                        name: L["支付密码"],
                        path: "/member/pwd/pay"
                    },
                    {
                        code: "MemberResetPassword",
                        name: L["重置密码"],
                        path: "/member/pwd/reset"
                    },
                    {
                        code: "memberAddressList",
                        name: L["收货地址"],
                        path: "/member/address/list"
                    }
                ]
            },
            {
                code: "money",
                icon: "icon-gerenzhongxincaichanzhongxin",
                name: L["我的钱包"],
                path: "/member/index",
                limit: store.state.memberInfo.isLookWallet ? false : true,
                child: [
                    {
                        code: "MemberBalance",
                        name: L["我的余额"],
                        path: "/member/balance"
                    },
                    {
                        code: "MemberRecharge",
                        name: L["账户充值"],
                        path: "/member/recharge"
                    },
                    {
                        code: "MemberbankCard",
                        name: "我的银行卡",
                        path: "/member/bankCard"
                    },
                    {
                        code: "MemberOpenAccount",
                        name: "账户信息",
                        path: "/member/openAccount"
                    }
                    // {
                    //   code: "MemberCoupon",
                    //   name: "我的优惠券",
                    //   path: "/member/coupon"
                    // },
                    // {
                    //   code: "MyPoint",
                    //   name: "我的积分",
                    //   path: "/member/myPoint"
                    // }
                ]
            },
            {
                code: "mySupplier",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["我的供应商"],
                path: "/member/index",
                //limit:store.state.memberInfo.isLookWallet==1?false:true,
                child: [
                    {
                        code: "mySupplierList",
                        name: L["我的供应商"],
                        path: "/member/mySupplier/mySupplierList"
                    }
                    //{
                    //  code: "SupplierBlacklist",
                    //  name: L["供应商黑名单"],
                    //  path: "/member/mySupplier/SupplierBlacklist"
                    //},
                ]
            },
            {
                code: "payment",
                icon: "icon-gerenzhongxinguanzhu",
                name: L["账期管理"],
                path: "/member/index",
                limit: memberInfo.data.roleIds
                    ? !JSON.parse(memberInfo.data.roleIds?.split(",")[0]) &&
                      !JSON.parse(memberInfo.data.roleIds?.split(",")[1]) &&
                      !JSON.parse(memberInfo.data.roleIds?.split(",")[2])
                    : true,
                child: [
                    {
                        code: "MonthlyStatement",
                        name: L["当月账单"],
                        path: "/member/payment/MonthlyStatement"
                    },
                    {
                        code: "Settlement",
                        name: L["账期结算"],
                        path: "/member/payment/Settlement"
                    },
                    {
                        code: "HistoricalStatement",
                        name: L["历史账单"],
                        path: "/member/payment/HistoricalStatement"
                    }
                    //{
                    //  code: "SupplierBlacklist",
                    //  name: L["供应商黑名单"],
                    //  path: "/member/mySupplier/SupplierBlacklist"
                    //},
                ]
            }
        ];
        const testIfShow = (nav_info) => {
            if (nav_info.code == "MemberResetPassword") {
                if (store.state.memberInfo.hasPayPassword == 1) {
                    return true;
                } else {
                    return false;
                }
            } else if (nav_info.code == "MemberOpenAccount") {
                if (store.state.memberInfo.memberUserType == 0) {
                    return true;
                } else {
                    return false;
                }
            } else if (nav_info.code == "pointOrderList") {
                if (store.state.memberInfo.memberUserType != 1) {
                    if (store.state.memberInfo.isLookWallet) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    return true;
                }
            } else if (nav_info.code == "memberApprovalList") {
                if (store.state.memberInfo.memberUserType != 0) {
                    return true;
                } else {
                    return false;
                }
            } else if (nav_info.code == "memberOrderList") {
                if (store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return true;
                } else if (!store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return false;
                } else {
                    return true;
                }
            } else if (nav_info.code == "MemberOrderEvaluation") {
                if (store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return true;
                } else if (!store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return false;
                } else {
                    return true;
                }
            } else if (nav_info.code == "pointOrderList") {
                if (store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return true;
                } else if (!store.state.memberInfo.isLookOrder && store.state.memberInfo.memberUserType == 2) {
                    return false;
                } else {
                    return true;
                }
            } else if (nav_info.code == "MonthlyStatement") {
                if (store.state.memberInfo.memberUserType == 2 && !memberInfo.data.roleIds) {
                    return false;
                } else if (
                    store.state.memberInfo.memberUserType == 2 &&
                    memberInfo.data.roleIds &&
                    !JSON.parse(memberInfo.data.roleIds?.split(",")[0])
                ) {
                    return false;
                } else {
                    return true;
                }
            } else if (nav_info.code == "Settlement") {
                if (store.state.memberInfo.memberUserType == 2 && !memberInfo.data.roleIds) {
                    return false;
                } else if (
                    store.state.memberInfo.memberUserType == 2 &&
                    memberInfo.data.roleIds &&
                    !JSON.parse(memberInfo.data.roleIds?.split(",")[1])
                ) {
                    return false;
                } else {
                    return true;
                }
            } else if (nav_info.code == "HistoricalStatement") {
                if (store.state.memberInfo.memberUserType == 2 && !memberInfo.data.roleIds) {
                    return false;
                } else if (
                    store.state.memberInfo.memberUserType == 2 &&
                    memberInfo.data.roleIds &&
                    !JSON.parse(memberInfo.data.roleIds?.split(",")[2])
                ) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };
        const getMenuList = () => {
            let maparr = [];
            proxy.$get("api/system/role/permission/menus/tree").then((res) => {
                if (res.code == 200) {
                    leftNavMenu.value = res.data;
                    //账户管理数据存储
                    res.data.length > 0 &&
                        res.data.map((item) => {
                            if (item.title == "会员中心") {
                                maparr = item.children;
                            }
                        });
                    store.commit("updateheadAccountData", { time: new Date().getTime(), menu: maparr });
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        onMounted(() => {
            getMenuList();
        });
        return { leftNavMenu, leftNavMenu2, curCode, testIfShow, memberInfo, platform };
    }
};
</script>

<style lang="scss" scoped>
.sld_member_left_nav {
    background-color: #fff;
    color: #666;
    font-size: 12px;
    border: 1px solid #ebebeb;
    border-top: 1px solid #fff;
    margin-top: -1px;
    padding-bottom: 100px;
    font-family: MicrosoftYaHei;
    width: 180px;
    float: left;
    margin-bottom: 20px;

    .menu {
        margin: 10px 0;
        padding-left: 15px;
        font-size: 15px;
        font-weight: 600;
        font-family: MicrosoftYaHei-Bold;
        display: block;
        color: #666;

        i {
            font-size: 16px;
            font-weight: 400;
            color: #888;
            margin-right: 12px;
        }
    }

    .submenu {
        line-height: 26px;
        padding-left: 53px;
        color: #333333;
        display: block;

        &:hover {
            font-weight: bold;
            color: $colorMain2;
        }

        &.cur_code_active {
            font-weight: bold;
            color: $colorMain2;
        }
    }
}
</style>
