<template>
    <div class="sld_prise_login">
        <div class="sld_login_header">
            <div class="content">
                <router-link tag="a" class="l_logo" :to="`/index`">
                    <img class="img" :src="platform == 1 ? gongcaiLogo : jicaiLogo" :onerror="defaultImg" alt />
                </router-link>
                <div class="r_register_wrap">
                    {{ L["还没注册？"] }}
                    <a href="javascript:void(0)" class="go_register_btn" @click="goToPage('/register')">
                        {{ L["去注册"] }}
                    </a>
                </div>
            </div>
        </div>
        <div class="sld_login_content">
            <!-- <img class="bg" :src="configInfo.main_user_register_bg" :onerror='defaultBgImg' alt /> -->
            <div class="login">
                <div class="prise_regise">
                    <div class="prise_reg_tit">企业注册帐号</div>
                    <!-- 企业注册 start -->
                    <div class="prise_reg_box">
                        <div class="prise_reg_left">
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/person.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    maxlength="250"
                                    placeholder="请输入企业联系人姓名"
                                    class="regist_tx_inp"
                                    v-model="medata.corpContactName"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpContactName"
                                    @click="medata.corpContactName = ''"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/mobile.png" alt="" />
                                </div>
                                <input
                                    type="number"
                                    placeholder="请输入企业联系人手机号"
                                    class="regist_tx_inp"
                                    v-model="name"
                                />
                                <img
                                    v-if="name"
                                    @click="name = ''"
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/graphical.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入图形验证码"
                                    class="regist_tx_inp"
                                    maxlength="4"
                                    v-model="imgCode"
                                    style="width: 318px"
                                />
                                <!-- <img src="../../../assets/register/close.png" alt="" class="close"> -->
                                <img
                                    :src="showCodeImg"
                                    class="img_code"
                                    @click="getImgCode"
                                    style="height: 39px; margin-right: -1px"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/verification.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入验证码"
                                    maxlength="6"
                                    class="regist_tx_inp"
                                    v-model="password"
                                />
                                <a href="javascript:void(0);" class="send_code" @click="getSmsCode">{{
                                    countDownM ? countDownM + L["s后获取"] : L["获取验证码"]
                                }}</a>
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/enterprise.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入企业名称"
                                    maxlength="250"
                                    class="regist_tx_inp"
                                    v-model="medata.corpName"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpName"
                                    @click="medata.corpName = ''"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/enterprise.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入企业简称"
                                    maxlength="250"
                                    class="regist_tx_inp"
                                    v-model="medata.corpNameAs"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpNameAs"
                                    @click="medata.corpNameAs = ''"
                                />
                            </div>
                            <div class="regist_box regist_box_up">
                                <div class="regist_img">
                                    <img src="../../../assets/register/license.png" alt="" />
                                </div>
                                <div class="regist_up_con">
                                    <div
                                        style="width: 130px; display: flex; flex-direction: column; align-items: center"
                                    >
                                        <div class="upload_con" @click="uploadFile('positive')">
                                            <input
                                                id="file"
                                                class="filepath"
                                                @change="upload_img($event, 'positive', 'positiveImg')"
                                                ref="uploadFileDOM"
                                                type="file"
                                                name="image"
                                                size="1"
                                                style="display: none"
                                                accept="image/gif,image/jpeg,image/gif,image/png"
                                            />
                                            <img
                                                src="../../../assets/register/jia.png"
                                                alt=""
                                                class="jia"
                                                v-if="!urlList.positiveImg"
                                            />
                                            <img
                                                :src="urlList.positiveImg"
                                                alt=""
                                                v-if="urlList.positiveImg"
                                                class="up_img"
                                            />
                                            <p class="upload_p" v-if="!urlList.positiveImg">上传照片</p>
                                        </div>
                                        <p class="upload_ti" v-if="!urlList.positiveImg">请上传营业执照</p>
                                    </div>
                                </div>
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/file.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入组织代码"
                                    class="regist_tx_inp"
                                    maxlength="18"
                                    v-model="medata.corpCode"
                                    @input="medata.corpCode = medata.corpCode.replace(/[\W]/g, '')"
                                    @blur="setFocus_o"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpCode"
                                    @click="medata.corpCode = ''"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/type.png" alt="" />
                                </div>
                                <p class="regis_lei">公司类型</p>
                                <el-select v-model="medata.corpType" placeholder="请选择">
                                    <el-option
                                        v-for="item in companyList"
                                        :key="item.value"
                                        :label="item.value"
                                        :value="item.value"
                                    >
                                    </el-option>
                                </el-select>
                                <!-- <input type="text" placeholder="请输入企业简称" class="regist_tx_inp">
                            <img src="../../../assets/register/close.png" alt="" class="close"> -->
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/person.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入法人姓名"
                                    class="regist_tx_inp"
                                    maxlength="25"
                                    v-model="medata.corpLegalName"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpLegalName"
                                    @click="medata.corpLegalName = ''"
                                />
                            </div>
                            <!-- <div class="regist_box">
                            <div class="regist_img">
                                <img src="../../../assets/register/identity.png" alt="">
                            </div>
                            <input type="text" placeholder="请输入法人身份证" class="regist_tx_inp" maxlength="18" v-model="medata.corpLegalCode">
                            <img src="../../../assets/register/close.png" alt="" class="close" v-if="medata.corpLegalCode" @click="medata.corpLegalCode=''">
                        </div> -->
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/mobile.png" alt="" />
                                </div>
                                <input
                                    type="number"
                                    placeholder="法人手机号"
                                    class="regist_tx_inp"
                                    maxlength="11"
                                    v-model="medata.corpLegalTel"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpLegalTel"
                                    @click="medata.corpLegalTel = ''"
                                />
                            </div>
                        </div>
                        <div class="prise_reg_rhght">
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/identity.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入法人身份证号"
                                    class="regist_tx_inp"
                                    maxlength="18"
                                    v-model="medata.corpLegalCode"
                                    @input="legalCode('corpLegalCode')"
                                />
                                <img
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                    v-if="medata.corpLegalCode"
                                    @click="medata.corpLegalCode = ''"
                                />
                            </div>
                            <div class="regist_box regist_box_up">
                                <div class="regist_img">
                                    <img src="../../../assets/register/license.png" alt="" />
                                </div>
                                <div class="regist_up_con">
                                    <div
                                        style="width: 130px; display: flex; flex-direction: column; align-items: center"
                                    >
                                        <div class="upload_con" @click="uploadFile('identityone')">
                                            <input
                                                id="file"
                                                class="filepath"
                                                @change="upload_img($event, 'identityone', 'identityoneImg')"
                                                ref="uploadFileDOMone"
                                                type="file"
                                                name="image"
                                                size="1"
                                                style="display: none"
                                                accept="image/gif,image/jpeg,image/gif,image/png"
                                            />
                                            <img
                                                :src="urlList.identityoneImg"
                                                alt=""
                                                v-if="urlList.identityone"
                                                class="up_img"
                                            />
                                            <img
                                                src="../../../assets/register/jia.png"
                                                alt=""
                                                class="jia"
                                                v-if="!urlList.identityone"
                                            />
                                            <p class="upload_p" v-if="!urlList.identityone">上传照片</p>
                                        </div>
                                        <p class="upload_ti" v-if="!urlList.identityone">法人身份证正面</p>
                                    </div>
                                    <div
                                        style="
                                            width: 130px;
                                            display: flex;
                                            flex-direction: column;
                                            align-items: center;
                                            margin-left: 20px;
                                        "
                                    >
                                        <div class="upload_con" @click="uploadFile('identitytwo')">
                                            <input
                                                id="file"
                                                class="filepath"
                                                @change="upload_img($event, 'identitytwo', 'identitytwoImg')"
                                                ref="uploadFileDOMtwo"
                                                type="file"
                                                name="image"
                                                size="1"
                                                style="display: none"
                                                accept="image/gif,image/jpeg,image/gif,image/png"
                                            />
                                            <img
                                                :src="urlList.identitytwoImg"
                                                alt=""
                                                v-if="urlList.identitytwo"
                                                class="up_img"
                                            />
                                            <img
                                                src="../../../assets/register/jia.png"
                                                alt=""
                                                class="jia"
                                                v-if="!urlList.identitytwo"
                                            />
                                            <p class="upload_p" v-if="!urlList.identitytwo">上传照片</p>
                                        </div>
                                        <p class="upload_ti" v-if="!urlList.identitytwo">法人身份证反面</p>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="regist_box">
                            <div class="regist_img">
                                <img src="../../../assets/register/person.png" alt="">
                            </div>
                            <input type="text" placeholder="请输入企业联系人姓名" class="regist_tx_inp" v-model="medata.enterpriseContactName">
                            <img src="../../../assets/register/close.png" alt="" class="close" v-if="medata.enterpriseContactName" @click="medata.enterpriseContactName=''">
                        </div> -->
                            <div class="regist_box regist_box_right">
                                <div class="regist_img">
                                    <img src="../../../assets/register/enterprise.png" alt="" />
                                </div>
                                <div class="regist_con">
                                    <el-cascader
                                        ref="cascaderAddr"
                                        v-model="form.data.area"
                                        :options="areaDataArray"
                                        placeholder="请选择企业三级地址"
                                        :props="cascaderProps"
                                        clearable
                                    ></el-cascader>
                                    <textarea
                                        name=""
                                        id=""
                                        placeholder="请输入详细地址"
                                        v-model="medata.corpDetailAddress"
                                        maxlength="500"
                                    ></textarea>
                                    <!-- <input type="text" name="" id="" class="reg_rig_inp" placeholder="请填写企业地址"> -->
                                </div>
                            </div>
                            <div class="regist_box" v-if="isShow">
                                <div class="regist_img">
                                    <img src="../../../assets/register/bank.png" alt="" />
                                </div>
                                <div class="regist_bankName">
                                    <el-select
                                        v-model="medata.corpBankName"
                                        filterable
                                        placeholder="请选择银行名称"
                                        @change="handleBank"
                                    >
                                        <el-option
                                            v-for="item in bankList.data"
                                            :key="item.bankCode"
                                            :label="item.bankName"
                                            :value="item.bankCode"
                                        />
                                    </el-select>
                                </div>
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/address.png" alt="" />
                                </div>
                                <div class="regist_area">
                                    <el-cascader
                                        placeholder="请选择开户行所在省/市"
                                        clearable
                                        :props="nodelistProps"
                                        @change="nodeChange"
                                    />
                                </div>
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/address.png" alt="" />
                                </div>
                                <div class="regist_bankName" v-if="isSubbranch">
                                    <el-select
                                        v-model="medata.corpBankOpenAddress"
                                        filterable
                                        placeholder="请输入开户行地址"
                                        @change="handlesubBank"
                                    >
                                        <el-option
                                            v-for="item in subbranchList.data"
                                            :key="item.bankCode"
                                            :label="item.bankName"
                                            :value="item.bankName"
                                        />
                                    </el-select>
                                </div>
                                <div class="regist_bankName" v-if="!isSubbranch">
                                    <el-select
                                        v-model="medata.corpBankOpenAddress"
                                        filterable
                                        placeholder="请输入开户行地址"
                                        @change="handlesubBank"
                                    >
                                        <el-option
                                            v-for="item in subbranchList.data"
                                            :key="item.bankCode"
                                            :label="item.bankName"
                                            :value="item.bankName"
                                        />
                                    </el-select>
                                </div>
                                <!-- <input type="text" placeholder="请输入开户行地址" maxlength="250" class="regist_tx_inp" v-model="medata.enterpriseBankOpenAddress">
                            <img v-if="medata.enterpriseBankOpenAddress" @click="medata.enterpriseBankOpenAddress=''" src="../../../assets/register/close.png" alt="" class="close"> -->
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/card.png" alt="" />
                                </div>
                                <input
                                    type="number"
                                    placeholder="请输入银行账号"
                                    maxlength="250"
                                    class="regist_tx_inp"
                                    v-model="medata.corpBankAccount"
                                />
                                <img
                                    v-if="medata.corpBankAccount"
                                    @click="medata.corpBankAccount = ''"
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                />
                            </div>
                            <div class="regist_box">
                                <div class="regist_img">
                                    <img src="../../../assets/register/short.png" alt="" />
                                </div>
                                <input
                                    type="text"
                                    placeholder="请输入企业邮箱"
                                    maxlength="250"
                                    class="regist_tx_inp"
                                    v-model="medata.corpEmail"
                                />
                                <img
                                    v-if="medata.corpEmail"
                                    @click="medata.corpEmail = ''"
                                    src="../../../assets/register/close.png"
                                    alt=""
                                    class="close"
                                />
                            </div>
                        </div>
                    </div>
                    <!-- 企业注册 end -->
                    <!-- 立即注册 start -->
                    <div class="foot_box">
                        <div class="register_btn" @click="login">立即注册</div>
                        <div class="register_btn_p">
                            已有帐号,
                            <p @click="goToPage('/login')">去登录></p>
                        </div>
                    </div>
                    <!-- 立即注册 end -->
                    <!-- 隐私协议 start -->
                    <div class="agree_wrap">
                        <input type="checkbox" :class="{ checkbox: true, default: true, checked: agreeFlag }" />
                        <span class="agree_selected iconfont icon-finish" @click="agree"></span>
                        <span class="text">
                            我同意<router-link tag="a" class="agreement" :to="`/agreement`"
                                >《用户注册协议》</router-link
                            ><router-link tag="a" class="agreement" :to="`/agreement?code=privacy_policy`"
                                >《隐私政策》</router-link
                            >
                        </span>
                    </div>
                    <!-- 隐私协议 end -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { ref, getCurrentInstance, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { ElMessage, ElPagination } from "element-plus";
import { apiUrl, apiUrlSub, platform } from "@/utils/config";
import axios from "axios";
import areaData from "../../../assets/area.json";
import { isShowTime } from "../../../utils/common";

export default {
    //name: "Register",
    setup() {
        const internalInstance = getCurrentInstance();
        const store = useStore();
        const agreeFlag = ref(false); //同意注册协议标识，默认不同意
        const name = ref(""); //用户名
        const route = useRoute();
        const errorMsg = ref(); //错误提示
        const password = ref(""); //密码
        const imgCode = ref(""); //图形验证码
        const areaDataArray = areaData; //地址
        const cascaderAddr = ref(null); //地址ref
        const form = reactive({ data: {} }); //地址code
        const nodeList = ref([]);
        const cityList = ref([]);
        const cascaderProps = { label: "regionName", value: "regionCode" }; //地址数据
        const nodelistProps = {
            lazy: true,
            lazyLoad(node, resolve) {
                const { level } = node;
                getNodeList();
                setTimeout(() => {
                    if (level == 0) {
                        let nodes = nodeList.value.map((item) => ({
                            value: item.provinceCode,
                            label: item.provinceName,
                            leaf: level >= 1
                        }));
                        resolve(nodes);
                    }

                    if (level == 1) {
                        let { value, label } = node;
                        getCityList(value, label);
                        setTimeout(() => {
                            let nodes = cityList.value.map((item) => ({
                                value: item.cityCode,
                                label: item.cityName,
                                leaf: level >= 1
                            }));
                            resolve(nodes);
                        }, 200);
                    }
                }, 500);
            }
        };
        const router = useRouter();
        const { proxy } = getCurrentInstance();
        const L = proxy.$getCurLanguage();
        const countDownM = ref(0); //短信验证码倒计时
        const timeOutId = ref(""); //定时器的返回值
        const showCodeImg = ref(""); //图形验证码图片
        const imgCodeKey = ref(""); //图形验证码的key
        const timestampImg = ref(""); //图形验证码时间戳
        const timestampVal = ref(""); //时间戳
        const enterpriseCodeFlag = ref(true); //组织代码查重判断
        const configInfo = ref(store.state.configInfo);
        const gongcaiLogo = ref(require("../../../assets/gongcaiLogo.jpg"));
        const jicaiLogo = ref(require("../../../assets/jicaiLogo.jpg"));
        const defaultImg = ref('this.src="' + require("../../../assets/common_top_logo.png") + '"');
        const defaultBgImg = ref('this.src="' + require("../../../assets/login_bg.png") + '"');
        const preventFre = ref(false);
        const companyList = reactive([
            //公司类型
            {
                value: "公司"
            },
            {
                value: "个人"
            }
        ]);
        const medata = reactive(
            //数据
            {
                corpAddressAll: "", //省市区组合
                corpBankAccount: "", //银行账号
                corpBankName: "", //开户银行
                nodeCodeName: "", //开户行省名称
                cityCodeName: "", //开户行市名称
                corpBankOpenAddress: "", //开户地址
                corpCityCode: "", //城市编码
                corpCode: "", //企业组织代码
                corpContactName: "", //企业联系人姓名
                corpDetailAddress: "", //详细地址
                corpDistrictCode: "", //区县编码
                corpEmail: "", //邮箱
                corpImg: "", //企业营业执照
                corpLegalCode: "", //法人身份证
                corpImgBack: "", //法人身份证反面
                corpImgFront: "", //法人身份证正面
                corpLegalName: "", //法人姓名
                corpLegalTel: "", //法人手机
                corpName: "", //	企业名称
                corpNameAs: "", //企业简称
                corpProvinceCode: "", //省份编码
                corpType: "" //公司类型
            }
        );
        let urlList = reactive({
            positive: "",
            positiveImg: "",
            identityone: "",
            identityoneImg: "",
            identitytwo: "",
            identityoneImg: ""
        });
        const uploadFileDOM = ref(null); //ref上传图片
        const uploadFileDOMone = ref(null); //ref上传图片
        const uploadFileDOMtwo = ref(null); //ref上传图片
        const bankName = ref("");
        const bankCode = ref(null);
        const is_show = ref(false);
        const isFocus = ref(false);
        const nodeItem = ref("");
        const cityItem = ref("");
        const cityvalue = ref("");
        const isSubbranch = ref(false);
        const subbranchList = reactive({ data: [] });
        const cityBankItem = ref("");
        const bankList = reactive({ data: [] });
        const isShow = ref(false);
        // 唤起上传
        const uploadFile = (flag) => {
            if (flag == "positive") {
                uploadFileDOM.value.click();
            }
            if (flag == "identityone") {
                uploadFileDOMone.value.click();
            }
            if (flag == "identitytwo") {
                uploadFileDOMtwo.value.click();
            }
        };

        // 上传图片事件
        const upload_img = (e, path, img) => {
            let data = new FormData();
            data.append("file", e.target.files[0]);
            data.append("source", "headImg");
            let config = {
                headers: { "Content-Type": "multipart/form-data" }
            };
            let url = apiUrlSub + "api/shopp/upload/oss";
            if (e.target.files[0].size < Math.pow(1024, 2) * 4) {
                axios.post(url, data, config).then((res) => {
                    console.log(res);
                    if (res.status == 200) {
                        ElMessage.success("上传图片成功");
                        urlList[img] = res.data.msg;
                        urlList[path] = res.data.msg;
                        console.log(urlList[img]);
                    } else {
                        ElMessage.warning("上传图片失败");
                    }
                });
            } else {
                ElMessage.warning("图像应小于4MB");
                tempSrc.value = avatar.value;
            }
        };
        const nodeChange = (e) => {
            nodeItem.value = e[0];
            cityItem.value = e[1];
            for (let i = 0; i < nodeList.value.length; i++) {
                if (nodeList.value[i].nodeCode == e[0]) {
                    medata.nodeCodeName = nodeList.value[i].nodeName;
                    break;
                }
            }
            for (let i = 0; i < cityList.value.length; i++) {
                if (cityList.value[i].cityCode == e[1]) {
                    medata.cityCodeName = cityList.value[i].cityName;
                    break;
                }
            }
            getCityBankList();
        };
        const getCityList = (value, label) => {
            let param = {
                provinceCode: value,
                cityName: ""
            };
            proxy.$get("api/pay/common/queryCity", param).then((res) => {
                if (res.code == 200) {
                    cityList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        const getCityBankList = () => {
            let param = {};
            param.cityCode = cityItem.value;
            param.bankCode = medata.corpBankName;
            param.nodeCode = nodeItem.value;
            if (cityBankItem.value) {
                param.keyword = cityBankItem.value;
            }
            proxy.$get("api/pay/common/queryBankBranch", param).then((res) => {
                if (res.code == 200) {
                    subbranchList.data = res.data;
                    isSubbranch.value = true;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        // watch(cityItem, () => {
        //     cityBankItem.value = '';
        //     getCityBankList();
        // });
        // watch(nodeItem, () => {
        //     cityBankItem.value = '';
        //     cityvalue.value = ''
        //     getCityList();
        // });
        const getNodeList = () => {
            proxy.$get("api/pay/common/queryProvince", bankName).then((res) => {
                if (res.code == 200) {
                    nodeList.value = res.data;
                } else {
                    ElMessage.warning(res.msg);
                }
            });
        };
        // 防抖函数
        const inputName = (type) => {
            proxy
                .$get("api/pay/common/queryBankOrg", {
                    bankName: bankName.value
                })
                .then((res) => {
                    if (res.code == 200) {
                        bankList.data = res.data;
                        isShow.value = true;
                    } else {
                        ElMessage.warning(res.msg);
                    }
                });
        };
        const handleBank = (e) => {
            console.log(e);
            medata.corpBankName = e;
        };
        const handlesubBank = (e) => {
            console.log(e);
        };
        //点击注册
        const login = () => {
            if (!medata.corpContactName.trim()) {
                ElMessage.warning("请输入企业联系人姓名");
                return;
            }
            //console.log(typeof name.value,6767);
            if (!name.value.toString().trim()) {
                ElMessage.warning("请输入手机号");
                return;
            } else {
                 let checkMobile = proxy.$checkPhone(name.value);
                 if (checkMobile !== true) {
                     ElMessage.warning('请输入正确的手机号')
                     return false;
                 }
            }
            if (!imgCode.value.toString().trim()) {
                ElMessage.warning("请输入图形验证码");
                return;
            }
            console.log(565655);
            if (!password.value.toString().trim()) {
                ElMessage.warning("请输入短信验证码");
                return;
            } else {
                let passwords = proxy.$checkSmsCode(password.value);
                if (passwords !== true) {
                    ElMessage.warning("请输入正确的短信验证码");
                    return false;
                }
            }
            if (!medata.corpName.trim()) {
                ElMessage.warning("请输入企业名称");
                return;
            }
            if (!medata.corpNameAs.trim()) {
                ElMessage.warning("请输入企业简称");
                return;
            }
            if (urlList.positive.length == 0) {
                ElMessage.warning("请上传营业执照");
                return;
            }
            if (!medata.corpCode.toString().trim()) {
                ElMessage.warning("请输入企业组织代码");
                return;
            }
            if (medata.corpCode.toString().trim().length < 9 || medata.corpCode.toString().trim().length > 18) {
                ElMessage.warning("请输入9~18位的组织代码!");
                return;
            }
            if (enterpriseCodeFlag.value) {
                if (!enterpriseCodeFlags.value) {
                    ElMessage.warning("该组织代码已注册，请联系管理员!");
                }
                return;
            }
            if (!medata.corpType.trim()) {
                ElMessage.warning("请选择公司类型");
                return;
            }
            // if(!medata.enterpriseLegalName.trim()){
            //     ElMessage.warning('请输入法人姓名')
            //     return
            // }
            // if(!medata.corpLegalCode.trim()){
            //     ElMessage.warning('请输入法人身份证')
            //     return
            // }else {
            //     let LegalCode = proxy.$checkIdentity(medata.corpLegalCode);
            //     if (LegalCode !== true) {
            //         ElMessage.warning('请输入正确的法人身份证')
            //         return false;
            //     }
            // }
            // if(!medata.enterpriseLegalTel.trim()){
            //     ElMessage.warning('请输入法人手机号')
            //     return
            // }else {
            //     // let checkMobile = proxy.$checkPhone(medata.enterpriseLegalTel);
            //     // if (checkMobile !== true) {
            //     //     ElMessage.warning('请输入正确的手机号')
            //     //     return false;
            //     // }
            // }
            // if(!medata.corpLegalCode.trim()){
            //     ElMessage.warning('请输入法人身份证')
            //     return
            // }else {
            //     let LegalCode = proxy.$checkIdentity(medata.corpLegalCode);
            //     if (LegalCode !== true) {
            //         ElMessage.warning('请输入正确的法人身份证')
            //         return false;
            //     }
            // }
            // if(urlList.identityone.length==0){
            //     ElMessage.warning('请上传法人身份证正面')
            //     return
            // }
            // if(urlList.identitytwo.length==0){
            //     ElMessage.warning('请上传法人身份证反面')
            //     return
            // }

            if (!form.data.area) {
                ElMessage.warning("请选择地址");
                return;
            }
            if (!medata.corpDetailAddress.trim()) {
                ElMessage.warning("请输入详细地址");
                return;
            }
            // if(!medata.enterpriseBankName.trim()){
            //     ElMessage.warning('请输入开户银行')
            //     return
            // }

            // if (
            //     !medata.corpBankAccount.toString().trim() ||
            //     (medata.corpBankAccount.toString().trim().length < 9 &&
            //         medata.corpBankAccount.toString().trim().length > 30)
            // ) {
            //     ElMessage.warning("请输入8-30位银行账号");
            //     return;
            // }

            // if(!medata.nodeCodeName  || !medata.cityCodeName){
            //     ElMessage.warning('请选择开户行所在省市')
            //     return
            // }
            // if(!medata.enterpriseBankOpenAddress.trim()){
            //     ElMessage.warning('请输入开户地址')
            //     return
            // }
            // if(!medata.enterpriseEmail.trim()){
            //     ElMessage.warning('请输入企业邮箱')
            //     return
            // }else {
            //     let Email = proxy.$checkEmail(medata.enterpriseEmail);
            //     if (Email !== true) {
            //         ElMessage.warning('请输入正确的企业邮箱')
            //         return false;
            //     }
            // }
            if (!medata.corpDetailAddress.trim()) {
                ElMessage.warning("请输入详细地址");
                return;
            }

            if (!agreeFlag.value) {
                ElMessage.warning("请同意用户注册协议及隐私政策");
                return;
            }
            medata.corpProvinceCode = form.data.area[0];
            medata.corpCityCode = form.data.area[1];
            medata.corpDistrictCode = form.data.area[2];
            medata.corpImg = urlList.positive;
            medata.corpImgFront = urlList.identityone;
            medata.corpImgBack = urlList.identitytwo;
            medata.corpAddressAll = cascaderAddr.value.getCheckedNodes()[0].pathLabels.join("");
            let param = {};
            param = medata;
            param.corpContactMobile = name.value;
            param.phone = name.value;
            param.code = password.value;
            param.verifyCode = imgCode.value;
            param.verifyKey = imgCodeKey.value;
            param.memberSource = platform;
            console.log(cascaderAddr.value.getCheckedNodes()[0].pathLabels.join(""));
            // 如果未登录vuex中有购物车数据，需同步到该用户
            let cartInfo = [];
            let cartListData = store.state.cartListData;
            if (JSON.stringify(cartListData) != "{}") {
                cartListData.storeCartGroupList.map((cartListItem) => {
                    cartListItem.promotionCartGroupList.map((promotItem) => {
                        promotItem.cartList.map((cartItem) => {
                            cartInfo.push({
                                productId: cartItem.productId,
                                buyNum: cartItem.buyNum
                            });
                        });
                    });
                });
            }
            param.cartInfo = JSON.stringify(cartInfo);
            let url = "api/shopp/member/register/corp";
            proxy.$post(url, param, "application/x-www-form-urlencoded;charset=UTF-8").then((res) => {
                if (res.code == 200) {
                    // if(res.data.memberType=='审核中'){
                    router.replace({
                        path: "/registrationReview",
                        query: {
                            memberId: route.query.memberId
                        }
                    });
                    // }else{
                    //     //将用户信息存缓存，并跳转到首页
                    //     localStorage.setItem('access_token', res.data.access_token);
                    //     localStorage.setItem('refresh_token', res.data.refresh_token);
                    //     localStorage.setItem('time', new Date().getTime().toString());//存储refresh_token更新时间
                    //     //获取用户信息，并同步信息到vuex
                    //     proxy.$get("api/shopp/member/detail",{uscUserId:store.state.memberInfo.uscUserId}).then(res => {
                    //         if (res.code == 200) {
                    //             //清空购物车store
                    //             proxy.$sldStatEvent({ behaviorType: 'reg' })
                    //             store.commit('updateCartListData', {});
                    //             store.commit('updateMemberInfo', res.data);
                    //             router.back();
                    //         }
                    //     })
                    // }
                } else {
                    //提示错误
                    getImgCode();
                    ElMessage.warning(res.msg);
                    errorMsg.value = res.msg;
                }
            });
            return;
            router.push({
                path: "/registrationReview"
            });
        };
        //清空输入框内容
        const clearInputVal = (type) => {
            if (type == "name") {
                name.value = "";
            } else if (type == "password") {
                password.value = "";
            }
        };

        //获取短信验证码
        const getSmsCode = () => {
            if (preventFre.value) {
                return;
            } else if (countDownM.value) {
                return;
            }
            preventFre.value = true;
            let checkMobile = proxy.$checkPhone(name.value);
            let checkImgCode = proxy.$checkImgCode(imgCode.value);
            if (checkMobile !== true) {
                // ElMessage.warning('请输入正确的手机号')
                // errorMsg.value = checkMobile;
                // preventFre.value = false;
            } else if (checkImgCode !== true) {
                ElMessage.warning("请输入正确的图形验证码");
                // errorMsg.value = checkImgCode;
                preventFre.value = false;
            } else {
                let param = {};
                timestampVal.value = new Date().getTime();
                param.mobile = name.value;
                param.timestamp = timestampVal.value;
                param.verifyCode = imgCode.value;
                param.verifyKey = imgCodeKey.value;
                proxy.$get("api/shopp/member/send/sms/code", param).then((res) => {
                    if (res.code == 200) {
                        countDownM.value = 120;
                        countDown();
                        preventFre.value = false;
                    } else {
                        //提示错误
                        getImgCode();
                        ElMessage.warning(res.msg);
                        errorMsg.value = res.msg;
                        preventFre.value = false;
                    }
                });
            }
        };
        //倒计时
        const countDown = () => {
            countDownM.value--;
            if (countDownM.value == 0) {
                preventFre.value = false;
                clearTimeout(timeOutId.value);
            } else {
                timeOutId.value = setTimeout(countDown, 1000);
            }
        };
        //获取图形验证码
        const getImgCode = () => {
            timestampImg.value = new Date().getTime();
            proxy.$get("api/shopp/member/create/image/code", { timestamp: timestampImg.value }).then((res) => {
                if (res.code == 200) {
                    showCodeImg.value = res.data;
                }
            });
        };
        //验证图形验证码
        const verifyBlur = () => {
            let params = {
                timestamp: timestampImg.value,
                code: imgCode.value
            };
            proxy.$get("api/shopp/member/image/code/valid", params).then((res) => {
                if (res.code != 200) {
                    errorMsg.value = "图形验证码错误";
                    getImgCode();
                }
            });
        };
        //是否同意用户注册协议
        const agree = () => {
            agreeFlag.value = !agreeFlag.value;
        };
        getImgCode(); //获取图形验证码
        //通过replace方式跳转页面
        const goToPage = (type) => {
            router.replace({
                path: type
            });
        };

        watch([name, imgCode, password, medata], () => {
            password.value = password.value.toString().substring(0, 20);
            imgCode.value = imgCode.value.substring(0, 4);
            name.value = name.value.toString().substring(0, 11);
            medata.corpLegalTel = medata.corpLegalTel.toString().substring(0, 11);
            medata.corpBankAccount = medata.corpBankAccount.substring(0, 250);
            if (password.value || imgCode.value || name.value) {
                errorMsg.value = "";
            }
        });

        // 审核失败获取数据
        //const getReapply = ()=>{
        //    let param = {}
        //    param.memberId = route.query.memberId
        //    proxy.$get('v3/frontLogin/oauth/reapply',param).then(res => {
        //        if (res.state == 200) {
        //            for(var i in medata){
        //                medata[i]=res.data.memberExtend[i]
        //            }
        //            form.data.area = []
        //            form.data.area[0] = res.data.memberExtend.corpProvinceCode
        //            form.data.area[1] = res.data.memberExtend.corpCityCode
        //            form.data.area[2] = res.data.memberExtend.corpDistrictCode
        //            name.value = res.data.corpContactMobile
        //            urlList.positiveImg = res.data.memberExtend.enterpriseImgUrl
        //            urlList.positive = res.data.memberExtend.enterpriseImg
        //            urlList.identityoneImg = res.data.memberExtend.corpLegalCodeImgFrontUrl
        //            urlList.identityone = res.data.memberExtend.corpLegalCodeImgFront
        //            urlList.identitytwoImg = res.data.memberExtend.corpLegalCodeImgBackUrl
        //            urlList.identitytwo = res.data.memberExtend.corpLegalCodeImgBack
        //            if(route.query.memberId){
        //                setFocus_o()
        //            }
        //        }
        //    })
        //}

        // 输入框input事件
        const legalCode = (type) => {
            if (type == "corpLegalCode") {
                if (!/^[\da-z]+$/i.test(medata.corpLegalCode)) {
                    setTimeout(() => {
                        medata.corpLegalCode = medata.corpLegalCode.replace(/[^\w\/]/gi, "");
                    });
                }
            }
        };
        const enterpriseCodeFlags = ref(true);
        // 组织代码查重判断
        const setFocus_o = () => {
            console.log(medata.corpCode);
            if (!medata.corpCode.trim()) {
                ElMessage.warning("请输入企业组织代码");
                return;
            }
            if (medata.corpCode.trim().length < 9 || medata.corpCode.trim().length > 18) {
                ElMessage.warning("请输入9~18位的组织代码!");
                return;
            }
            let param = {};
            param.corpCode = medata.corpCode;
            proxy.$get("api/shopp/member/corp/register/code/isexist", param).then((res) => {
                if (res.code == 200) {
                    if (res.data) {
                        enterpriseCodeFlag.value = true;
                        ElMessage.warning("该组织代码已注册，请联系管理员!");
                        enterpriseCodeFlags.value = true;
                        setTimeout(() => {
                            enterpriseCodeFlags.value = false;
                        }, 1000);
                    } else {
                        enterpriseCodeFlag.value = false;
                    }
                }
            });
        };

        inputName();
        // getNodeList();
        onMounted(() => {
            document.body.classList.remove("el-popup-parent--hidden");
            if (route.query.memberId) {
                //getReapply()
            }
        });
        return {
            L,
            login,
            name,
            password,
            timestampVal,
            timestampImg,
            verifyBlur,
            clearInputVal,
            errorMsg,
            getSmsCode,
            countDownM,
            imgCode,
            showCodeImg,
            imgCodeKey,
            getImgCode,
            agreeFlag,
            agree,
            goToPage,
            configInfo,
            gongcaiLogo,
            jicaiLogo,
            platform,
            defaultImg,
            defaultBgImg,
            urlList,
            upload_img,
            uploadFileDOM,
            uploadFile,
            medata,
            companyList,
            areaDataArray,
            cascaderProps,
            nodelistProps,
            cascaderAddr,
            form,
            uploadFileDOMtwo,
            uploadFileDOMone,
            legalCode,
            setFocus_o,
            enterpriseCodeFlag,
            enterpriseCodeFlags,
            bankName,
            bankCode,
            is_show,
            isFocus,
            nodeList,
            nodeItem,
            cityList,
            cityItem,
            cityvalue,
            cityBankItem,
            bankList,
            isShow,
            isSubbranch,
            subbranchList,
            handleBank,
            getCityList,
            getCityBankList,
            getNodeList,
            inputName,
            nodeChange,
            handlesubBank
        };
    }
};
</script>
<style lang="scss" scoped>
@import "../../../style/priseRegister.scss";

.el-popup-parent--hidden {
    overflow: visible;
}

// ::v-deep .el-select .el-input__wrapper {
//     border: none !important;
//     box-shadow: none!important;
// }

// ::v-deep .el-input .el-input__wrapper.is-focus{
// box-shadow: none!important;
// }
// ::v-deep .el-select .el-input.is-focus .el-input__wrapper {
// border-color: #DCDFE6  !important;
// box-shadow: none!important;
// }
</style>
<style lang="scss">
.sld_prise_login {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none !important;
    }
    input[type="number"] {
        -moz-appearance: textfield;
    }

    .regist_con {
        width: 100%;
        padding-left: 14px;
        padding-top: 14px;
        .el-select {
            width: 391px;
            height: 30px;
            background: #f9f9f9;
            border-radius: 3px;
            .el-input__inner {
                border: none;
                background: #f9f9f9;
            }
        }
        .el-cascader {
            width: 100%;
            .el-input__inner {
                width: 100%;
            }
        }
    }
    .regist_area {
        width: 100%;
        .el-cascader {
            width: 100%;
            .el-input__inner {
                border: none;
                width: 100%;
            }
        }
    }
    .prise_reg_left {
        .el-select {
            width: 313px;
            // height: 30px;
            background: #f9f9f9;
            border-radius: 3px;
            .el-input__inner {
                border: none;
                height: 30px;
                background: #f9f9f9;
            }
            .el-input__suffix {
                top: -5px;
            }
        }
    }
    .regist_bankName {
        width: 100%;
        .el-select {
            width: 100%;
            .el-input__inner {
                border: none;
            }
        }
    }
}
</style>
